import { ResponseData, ResponseError, axiosInstance, Request } from '@/helpers'
const moduleName = 'proxy'
export const ProxyHelper = (link) =>{
  const req = Request();
  if(link) return axiosInstance.post(`/${moduleName}/download`,{link},req).then(ResponseData).catch(ResponseError)
  else return axiosInstance.get(`/${moduleName}`,req).then(ResponseData).catch(ResponseError)
}

export default {
  ProxyHelper,
}