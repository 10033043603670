<template>
  <div>
  <v-form v-model="form">
    <v-card>
      <v-card-title>
        CONFIG SYSTEM
      </v-card-title>
      <v-card-text>
          <v-row>
            <v-col
              cols="auto"
            >
              <v-text-field
                v-model="configs.HydraUser"
                label="Hydra User"
                :rules="[required('Hydra User')]"
              />
            </v-col>
            <v-col
              cols="auto"
            >
              <v-text-field
                v-model="configs.HydraPass"
                label="Hydra Password"
                :rules="[required('Hydra Password')]"
              />
            </v-col>
            <v-col
              cols="auto"
            >
              <v-text-field
                v-model="configs.CheckerGate"
                label="Checker Gate Default"
                :rules="[required('Checker Gate')]"
              />
            </v-col>
            <v-col
                cols="auto"
            >
              <v-textarea
                  v-model="configs.CheckerGateList"
                  label="Checker Gate List"
                  :rules="[required('Checker Gate List')]"
              />
            </v-col>
            <v-col
              cols="auto"
            >
              <v-checkbox
                color="secondary"
                v-model="configs.Checker"
                label="Turn On Checker"
              />
            </v-col>
            <v-col
              cols="auto"
            >
              <v-text-field
                v-model="configs.webshareUser"
                label="Webshare User"
                :rules="[required('Webshare User')]"
              />
            </v-col>
            <v-col
              cols="auto"
            >
              <v-text-field
                v-model="configs.websharePass"
                label="Webshare Password"
                :rules="[required('Webshare User')]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="auto"
            />
          </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          :disabled="!form"
          @click="doUpdate"
        >
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
  <loading :status="loader" />
    <v-card>
      <v-card-title>
        Webshare.IO Proxy Downloader
      </v-card-title>
      <v-card-text>
          <v-text-field
            v-model="ProxyLink"
            label="Proxy Link"
            :rules="[required('Proxy Link')]"
          />
          <v-divider />
          <v-row>
            <v-col
              cols="auto"
            />
          </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          :disabled="!ProxyLink"
          @click="doDownloadProxy"
        >
          Tải Proxy
        </v-btn>
      </v-card-actions>
      <loading :status="downloader" />
    </v-card>
  </div>
</template>

<script>
import SystemService from '@/modules/system.js';
import {ProxyHelper} from '@/modules/proxy.js';
import validations from '@/helpers/validations.js';
import loading from '@/components/base/loading.vue';
export default {
  components:{
    loading,
  },
  data () {
    return {
      ProxyLink:undefined,
      form:false,
      loader:false,
      downloader:false,
      configs:{}
    }
  },
  methods:{
    ...validations,
    doDownloadProxy(){
      this.downloader = true;
      ProxyHelper(this.ProxyLink).finally(()=>{
        this.downloader = false;
      })
    },
    doUpdate(){
      this.loader = true;
      SystemService.System(this.configs).finally(()=>{
        this.loader = false;
      })
    },
    doFetch(){
      this.loader = true;
      SystemService.System().then(resp=>{
        const { data } = resp;
        if(data) this.configs = data;
      }).finally(()=>{
        this.loader = false;
      })
    }
  },
  beforeDestroy(){
  },
  mounted(){
    this.doFetch();
  }
}
</script>

<style>

</style>